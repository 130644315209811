import { config } from '../cognitocmsapi/default_config.js'

config.devURL = `https://lkcms.${config.devbase}`
config.prodURL = 'https://cms.lakesidepark.com.au'
config.siteURL = 'https://lakesidepark.com.au'
config.baseURL = config.devbase == '' ? config.prodURL : config.devURL

config.isEcommerce = false
config.sentryDsn = 'https://1f958ac0479d4dc8a21f8af8e94d5360@glitchtip.logger.jm1.au/11'

export { config }
