<template>
  <div class="space-y-4">
    <form @submit.prevent="submitEnquiry">
      <div>
        <div class="cgn-heading">
          Venue(s)
        </div>
        <div class="flex flex-col gap-4 md:flex-row">
          <cgn-form-checkbox
            v-model="formValues.venue_main_track"
            label="Main Track"
          />
          <cgn-form-checkbox
            v-model="formValues.venue_dtc"
            label="Driver Training Centre"
          />
          <cgn-form-checkbox
            v-model="formValues.venue_function_room"
            label="Function Room"
          />
        </div>
      </div>
      <div class="flex flex-col gap-4 md:flex-row">
        <cgn-form-input
          v-model="formValues.business_name"
          label="Organiser / Business Name"
          class="w-full md:w-1/2"
          required
        />
        <cgn-form-input
          v-model="formValues.contact_name"
          label="Contact Name"
          class="w-full md:w-1/2"
          required
        />
      </div>
      <div class="flex flex-col gap-4 md:flex-row">
        <cgn-form-input
          v-model="formValues.email"
          label="Email"
          type="email"
          class="w-full md:w-1/2"
          required
        />
        <cgn-form-input
          v-model="formValues.phone"
          label="Phone"
          type="tel"
          class="w-full md:w-1/2"
          required
        />
      </div>

      <cgn-form-dropdown
        v-model="formValues.event_type"
        label="Event Type"
        :options="categories"
        required
        use_option_name
      />

      <cgn-form-textarea
        v-model="formValues.description"
        label="Details of the event"
        placeholder="Please be specific about the event you are planning"
        required
      />

      <div>
        <div class="py-2">
          Preferred Day(s) of the week
        </div>
        <div class="flex flex-col gap-4 md:flex-row">
          <cgn-form-checkbox v-model="formValues.prefer_mon" label="Monday" />
          <cgn-form-checkbox v-model="formValues.prefer_tue" label="Tuesday" />
          <cgn-form-checkbox v-model="formValues.prefer_wed" label="Wednesday" />
          <cgn-form-checkbox v-model="formValues.prefer_thu" label="Thursday" />
          <cgn-form-checkbox v-model="formValues.prefer_fri" label="Friday" />
          <cgn-form-checkbox v-model="formValues.prefer_sat" label="Saturday" />
          <cgn-form-checkbox v-model="formValues.prefer_sun" label="Sunday" />
        </div>
      </div>

      <div class="flex justify-end">
        <cgn-button
          type="submit"
          color-brand
        >
          Submit
        </cgn-button>
      </div>
    </form>
    <cgn-alert-success v-if="enquiry_ok">
      Thank you for your enquiry
    </cgn-alert-success>
    <cgn-alert-danger v-if="enquiry_bad">
      Sorry, something went wrong
    </cgn-alert-danger>
  </div>
</template>

<script setup lang="ts">
import { LakesideEventCategory } from '~/models/Lakeside/EventCategory'
import { $axios } from '~cognito/plugins/axios'

const formValues = ref({
  venue_main_track: false,
  venue_dtc: false,
  venue_function_room: false,
  business_name: '',
  contact_name: '',
  email: '',
  phone: '',
  event_type: '',
  description: '',
  prefer_mon: false,
  prefer_tue: false,
  prefer_wed: false,
  prefer_thu: false,
  prefer_fri: false,
  prefer_sat: false,
  prefer_sun: false,
})
const categories = ref([])
const enquiry_ok = ref(false)
const enquiry_bad = ref(false)

const submitEnquiry = () => {
  $axios
    .post('/api/v1/lakeside/event/enquiry', formValues.value)
    .then(() => {
      enquiry_ok.value = true
      enquiry_bad.value = false
    })
    .catch(() => {
      enquiry_ok.value = false
      enquiry_bad.value = true
    })
}
onMounted(() => {
  new LakesideEventCategory().find_many({})
    .then((data) => {
      categories.value = data.data.map((e) => {
        return {
          name: e.name,
          value: e.name,
        }
      })
    })
})
</script>
