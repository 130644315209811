<script lang="ts">
const image_width = 1184
</script>
<template>
  <div
    v-if="post.name" class="relative py-10"
  >
    <div
      class="absolute inset-y-0 left-3/4 hidden w-screen bg-gray-300 opacity-10 dark:bg-brand-600 lg:block"
    />
    <div class="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
      <div
        class="cgn-heading text-center text-4xl text-gray-900 dark:text-gray-100 lg:text-left"
      >
        {{ post.name }}
      </div>
    </div>
    <div class="mt-8 flex flex-col gap-8 lg:flex-row">
      <div class="relative lg:order-last lg:w-1/3">
        <svg
          class="absolute right-0 top-0 -mr-20 -mt-20 hidden lg:block"
          width="350"
          height="350"
          fill="none"
          viewBox="0 0 350 350"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="de316486-4a29-4312-bdfc-fbce2132a2c1"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                class="text-gray-200 dark:text-brand-600"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
        </svg>
        <div v-if="post.image" class="relative text-base">
          <figure>
            <cgn-lazy-image
              class="rounded-lg object-cover object-center shadow-lg"
              :image="post.image"
              width="1184"
            />
          </figure>
        </div>
      </div>
      <div class="lg:w-2/3">
        <div
          class="prose-brand prose dark:prose-invert lg:col-start-1 lg:row-start-1"
          v-html="post.content"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CognitoArticle } from '~cognito/models/Cognito/Article'

const props = defineProps({
  article: null
})

const post = ref<CognitoArticle>(new CognitoArticle())

useSchemaOrg([
  defineArticle({
    // name and description can usually be inferred
    image: computed(() => post.value?.image?.url),
    // datePublished: new Date(2020, 1, 1),
    // dateModified: new Date(2020, 1, 1),
  }),
])



const breadcrumbs = ref([{ name: 'Blog', url: '/blog' }])

async function loadArticle(url: string) {
  post.value.name = ''
  breadcrumbs.value = [{ name: 'Blog', url: '/blog' }]
  const data = await new CognitoArticle().find_one({
    url,
    image_aspect: 'Square',
    image_width,
  })
  post.value = data
}

watch(() => props.article, (newUrl) => {
  loadArticle(newUrl)
})
onMounted(() => {
  loadArticle(props.article)
})
onServerPrefetch(async () => {
  await loadArticle(props.article)
})
</script>
