<template>
  <div v-if="eventTitle">
    <div v-if="eventTitle.photo" class="relative">
      <div
        class="cgn-heading absolute bottom-5 left-5 font-display text-3xl font-semibold uppercase text-white drop-shadow-lg sm:text-5xl md:text-7xl"
        data-aos="flip-down"
      >
        {{ eventTitle.name }}
      </div>
      <cgn-lazy-image :image="eventTitle.photo" />
    </div>
    <div v-else class="py-10">
      <div
        class="cgn-heading text-center font-display text-3xl font-semibold uppercase text-white drop-shadow-lg sm:text-5xl md:text-7xl"
      >
        {{ eventTitle.name }}
      </div>
    </div>
    <div :class="props.containedClass">
      <div class="prose max-w-none dark:prose-dark" v-html="eventTitle.page_content" />
    </div>
    <cgn-page-builder-faq v-if="eventTitle.faq_group" :templatevar="{ group: eventTitle.faq_group.url }" />
    <div class="cgn-heading cgn-heading-1 text-center text-on-brand">
      <div class="pt-4">
        Upcoming Events
      </div>
    </div>
    <div class="mx-auto mb-3 w-full max-w-xl rounded-lg border border-brand-400 bg-brand-700 p-2">
      <div v-if="eventTitle.events?.length > 0" class="divide-y-2 divide-brand-300">
        <div v-for="event in eventTitle.events" :key="event.id" class="grid grid-cols-2 gap-4 p-2">
          <span class="flex flex-col">
            <span class="text-xl font-black uppercase">
              {{ event.start_time.format('EEE') }}
            </span>
            <span class="text-brand-300">
              {{ event.start_time.toHumanDateString(true) }}
            </span>
          </span>
          <span v-if="event.can_enter_online" class="flex justify-end">
            <div class="my-2 text-white">
              <cgn-button
                :url="`https://racers.world/event/${event.racers_event_id}/do/enter`"
                color-brand
              >
                Enter
              </cgn-button>
            </div>
          </span>
        </div>
      </div>
      <div v-else>
        <div class="p-2 text-lg">
          No upcoming events
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { gql } from 'graphql-request'
import { CognitoTime } from '~cognito/models/Cognito/Time'
import { $axios } from '~cognito/plugins/axios'

class Templatevars {
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
  containedClass: {
    type: String,
  },
  title: {
    type: String,
    required: true,
  },
})

const eventTitle = ref(null)

async function loadTitle() {
  const data = await $axios.graphql(gql`
query eventTypeQuery($url: String!) {
  lakesideEventType(url: $url) {
    id
    name
    page_content
    faq_group {
      url
    }
    enabled
    photo(image_aspect: "16x5", image_width: 1920) {
      url
      height
      width
    }
    events(first: 10, isFuture: true) {
      id
      name
      start_time
      can_enter_online
      racers_event_id
    }
  }
}
  `, {
    url: props.title,
  })
  eventTitle.value = data.lakesideEventType
  if (eventTitle.value) {
    // Map start_time to CognitoTime
    for (let index = 0; index < eventTitle.value.events.length; index++) {
      const event = eventTitle.value.events[index]
      event.start_time = new CognitoTime(event.start_time)
    }
  }
}

watch(() => props, loadTitle, {
  deep: true,
})

onMounted(() => {
  loadTitle()
})

onServerPrefetch(async () => {
  await loadTitle()
})
</script>
