<template>
  <div class="relative flex aspect-video items-center justify-center overflow-hidden md:aspect-auto md:h-[300px] lg:h-[500px]">
    <div
      class="relative z-20 rounded-xl"
    >
      <div>
        <cgn-lazy-image class="h-6 w-auto md:h-12" :image="lightLogo" />
      </div>
    </div>
    <video
      v-if="url"
      autoplay
      loop
      muted
      class="absolute z-10 w-full"
    >
      <source
        :src="url"
        type="video/mp4"
      >
    </video>
    <cgn-lazy-image class="absolute w-full" :image="slate" />
  </div>
</template>

<script lang="ts">
import { CognitoImage } from '~cognito/models/Cognito/Image'
import { CognitoVideo } from '~cognito/models/Cognito/Video'

class Templatevars {
  video?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})

const lightLogo = computed(() => {
  return usePagesStore().currentDomain.organisation_logo
})

const url = ref('')
const slate = ref(new CognitoImage())
async function loadVideo() {
  const data = await new CognitoVideo().find_one({
    id: props.templatevar.video,
  })
  url.value = data.file
  slate.value = data.slate_image
}
onMounted(() => {
  loadVideo()
})
onServerPrefetch(async () => {
  await loadVideo()
})
</script>
