<template>
  <div class="lg:flex lg:h-full lg:flex-col">
    <div ciass="text-muted">
      Legend
    </div>
    <div v-for="venue in venues" :key="venue.name" class="flex items-center text-lg">
      <i-heroicons-solid:clock class="mr-2 h-5 w-5" :style="{ color: venue.colour }" aria-hidden="true" />
      {{ venue.name }}
    </div>
    <header class="flex items-center justify-between border-b-2 border-darkbg-400 py-4 lg:flex-none">
      <div class="cgn-heading cgn-heading-1 text-base font-semibold">
        <time :datetime="format(curMonth, 'yyyy-MM')" class="flex flex-col gap-2 md:flex-row">
          <div>{{ format(curMonth, 'MMMM') }}</div>
          <div>{{ format(curMonth, 'yyyy') }}</div>
        </time>
      </div>
      <div class="flex items-center">
        <div class="relative flex items-center rounded-md bg-brand-500 shadow-sm md:items-stretch">
          <div class="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-brand-300" aria-hidden="true" />
          <button
            type="button"
            class="flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-on-brand md:w-9 md:px-2 md:hover:bg-brand-400"
            @click="subMonth"
          >
            <span class="sr-only">Previous month</span>
            <i-heroicons-solid:chevron-left class="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            type="button"
            class="hidden px-3.5 text-sm font-semibold text-on-brand hover:bg-brand-400 md:block"
            @click="goToday"
          >
            Today
          </button>
          <span class="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
          <button
            type="button"
            class="flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-on-brand md:w-9 md:px-2 md:hover:bg-brand-400"
            @click="addMonth"
          >
            <span class="sr-only">Next month</span>
            <i-heroicons-solid:chevron-right class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </header>
    <div>
      <div v-for="day in days" :key="day.date" :ref="(el) => addDayRef(el, day.isToday)">
        <div v-if="day.events.length > 0" class="py-4">
          <div class="text-xl">
            {{ format(day.day, 'EEEE do MMM') }}
          </div>
          <div class="cursor-pointer">
            <ol class="divide-y divide-brand-300 overflow-hidden rounded-lg bg-brand-600 text-sm shadow ring-1 ring-brand-400">
              <li
                v-for="event in day.events" :key="event.id" class="group flex p-4 pr-6 focus-within:bg-brand-500 hover:bg-brand-500"
                @click="openModal(event.id)"
              >
                <div class="flex-auto">
                  <p class="font-semibold text-on-brand">
                    {{ event.title }}
                  </p>
                  <time :datetime="event.datetimeISO" class="mt-2 flex items-center text-brand-200">
                    <i-heroicons-solid:clock class="mr-2 h-5 w-5" :style="{ color: event.backgroundColor }" aria-hidden="true" />
                    <template v-if="!event.starts_today">
                      {{ event.start_day }},
                    </template>
                    {{ event.start_time }}
                    -
                    <template v-if="!event.ends_today">
                      {{ event.end_day }},
                    </template>
                    {{ event.end_time }}
                  </time>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <cgn-button
        color-brand
        @click="addMonth"
      >
        <i-heroicons-solid:chevron-right class="inline h-5 w-5" aria-hidden="true" />
        Next month
      </cgn-button>
    </div>
  </div>

  <cgn-modal v-model="event_popup">
    <template #content>
      <h3 class="select-none text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
        {{ modal_event.name }}
      </h3>
      <div class="mt-2 text-info-600 dark:text-info-400">
        {{ selected_date }}
      </div>
      <div class="prose dark:prose-invert" v-html="modal_event.description" />
    </template>
    <template v-if="modal_event.can_enter_online" #button-footer>
      <div class="w-full bg-brand-500">
        <cgn-button
          :url="`https://racers.world/event/${modal_event.racers_event_id}/do/enter`"
          fullwidth
        >
          Enter Event
        </cgn-button>
      </div>
    </template>
  </cgn-modal>
</template>

<script setup lang="ts">
import { addDays, addHours, addMonths, format, formatISO, isAfter, isBefore, isSameDay, parse, parseISO, startOfDay, startOfMonth, subMinutes, subMonths } from 'date-fns'
import { gql } from 'graphql-request'
import { LakesideEvent } from '~/models/Lakeside/Event'
import { $axios } from '~cognito/plugins/axios'

const calendarEvents = ref<{
  id: number
  title: string
  start: string
  end: string
  backgroundColor: string
  textColor: string
}[]>([])

const curMonth = ref(startOfMonth(new Date()))
const selectedDate = ref('')
const modal_event = ref(new LakesideEvent())
const event_popup = ref(false)
const todayRef = ref()

const getEvents = async () => {
  const start = format(subMonths(curMonth.value, 1), 'yyyy-MM-dd')
  const end = format(addMonths(curMonth.value, 2), 'yyyy-MM-dd')
  const data = await $axios
    .get(`/api/v1/lakeside/event/calendar?start=${start}&end=${end}`)
  calendarEvents.value = data.data
}

const goToday = () => {
  curMonth.value = startOfMonth(new Date())
  nextTick(async () => {
    await getEvents()
    todayRef.value?.scrollIntoView({ behavior: 'smooth' })
  })
}
const addMonth = () => {
  curMonth.value = addMonths(curMonth.value, 1)
  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, 100)
}
const subMonth = () => {
  curMonth.value = subMonths(curMonth.value, 1)
}

const showOnCalendar = (startTime: Date, endTime: Date, day: Date) => {
  const startDay = startOfDay(startTime)
  const endDay = startOfDay(endTime)
  if (isSameDay(startDay, day)) {
    return true
  }
  if (isSameDay(endDay, day)) {
    return true
  }
  if (isAfter(endDay, day) && isBefore(startDay, day)) {
    return true
  }
  return false
}

class EventData {
  id: number
  title: string
  timespan: string
  start: Date
  end: Date
  start_day: string
  start_time: string
  end_day: string
  end_time: string
  starts_today: boolean
  ends_today: boolean
  datetimeISO: string
  backgroundColor: string
  textColor: string
  is_morning: boolean
  is_afternoon: boolean
  is_evening: boolean
  constructor(source?: Partial<EventData>) {
    this.id = 0
    this.title = ''
    this.timespan = ''
    this.start = new Date()
    this.end = new Date()
    this.start_day = ''
    this.start_time = ''
    this.end_day = ''
    this.end_time = ''
    this.starts_today = false
    this.ends_today = false
    this.datetimeISO = ''
    this.backgroundColor = ''
    this.textColor = ''
    this.is_morning = false
    this.is_afternoon = false
    this.is_evening = false
    Object.assign(this, source)
  }
}

const selected_date = computed(() => {
  if (!modal_event.value) {
    return ''
  }
  return format(parseISO(modal_event.value.start_time), 'MMMM d, yyyy')
})

const days = computed<{
  date: string
  day: Date
  isCurrentMonth: boolean
  isToday: boolean
  isSelected: boolean
  events: EventData[]
}[]>(() => {
  let calculateDate = curMonth.value
  const endDate = addMonths(calculateDate, 1)
  const currentMonthNumber = format(curMonth.value, 'MM')
  const data = []
  while (isBefore(calculateDate, endDate)) {
    // Calculate events for this day
    const todayEvents = calendarEvents.value.filter(e => showOnCalendar(parse(e.start, 'yyyy-MM-dd HH:mm:ss', new Date()), parse(e.end, 'yyyy-MM-dd HH:mm:ss', new Date()), calculateDate)).map((item) => {
      const start = parse(item.start, 'yyyy-MM-dd HH:mm:ss', new Date())
      const end = parse(item.end, 'yyyy-MM-dd HH:mm:ss', new Date())
      return new EventData({
        id: item.id,
        title: item.title,
        start,
        end,
        timespan: `${format(start, 'h:mmaaaaa')} - ${format(end, 'h:mmaaaaa')}`,
        start_day: format(start, 'dd MMM'),
        start_time: format(start, 'hh:mma'),
        end_day: format(end, 'dd MMM'),
        end_time: format(end, 'hh:mma'),
        starts_today: isSameDay(start, calculateDate),
        ends_today: isSameDay(end, calculateDate),
        datetimeISO: formatISO(start),
        backgroundColor: item.backgroundColor,
        textColor: item.textColor,
        is_morning: isBefore(start, addHours(calculateDate, 12)),
        is_afternoon: isAfter(start, subMinutes(addHours(calculateDate, 12), 1)) && isBefore(start, addHours(calculateDate, 17)),
        is_evening: isAfter(start, subMinutes(addHours(calculateDate, 17), 1)),
      })
    })
    data.push({
      date: format(calculateDate, 'yyyy-MM-dd'),
      day: calculateDate,
      isCurrentMonth: currentMonthNumber === format(calculateDate, 'MM'),
      isToday: isSameDay(new Date(), calculateDate),
      isSelected: format(calculateDate, 'yyyy-MM-dd') === selectedDate.value,
      events: todayEvents,
    })
    calculateDate = addDays(calculateDate, 1)
  }
  return data
})

watch(() => curMonth.value, getEvents)

const openModal = (id: number) => {
  new LakesideEvent().find_one({ url: String(id) })
    .then((data) => {
      modal_event.value = new LakesideEvent(data)
      event_popup.value = true
    })
}
const addDayRef = (element: any, is_today: boolean) => {
  if (is_today) {
    todayRef.value = element
  }
}

const venues = ref([])
onMounted(async () => {
  selectedDate.value = format(new Date(), 'yyyy-MM-dd')
  await getEvents()
  nextTick(() => {
    todayRef.value?.scrollIntoView({ behavior: 'smooth' })
  })
  const data = await $axios.graphql(gql`
  {
  lakesideVenues {
    name
    visible_to_public
    colour
  }
}`)
  venues.value = data.lakesideVenues.filter(e => e.visible_to_public)
})
</script>
