<template>
  <div>
    <div
      class="cgn-heading cgn-heading-1 text-center text-gray-900 dark:text-gray-100"
    >
      NEWS
    </div>
    <div class="flex flex-col gap-5 pt-4">
      <router-link
        v-for="post in posts"
        :key="post.id"
        :to="`/article/${post.slug}`"
        class="overflow-hidden rounded-lg border bg-white drop-shadow-md dark:border-brand-500 dark:bg-darkbg-700"
      >
        <div class="flex justify-between p-2">
          <div>
            <div class="cgn-heading cgn-heading-3 sm:cgn-heading-2 text-gray-900 dark:text-gray-100">
              {{ post.name }}
            </div>
            <div class="mt-3 text-base text-gray-600 dark:text-gray-200" v-html="post.blurb" />
          </div>
          <div v-if="post.publish_time" class="whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-100">
            {{ humanDateString(post.publish_time) }}
          </div>
        </div>
        <div class="bg-brand-500 hover:bg-brand-400">
          <cgn-button fullwidth>
            Read More
          </cgn-button>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CognitoArticle } from '~cognito/models/Cognito/Article'
import { CognitoTime } from '~cognito/models/Cognito/Time'

const posts = ref<CognitoArticle[]>([])
const humanDateString = (time) => {
  return new CognitoTime(time).toHumanDateString(true)
}

async function loadArticles() {
  posts.value = []
  const data = await new CognitoArticle().find_many({
    image_aspect: '4x3',
    image_width: 600,
    page_size: 20,
    orderby: 'display_start_time',
  })
  posts.value = data.data
}

onMounted(() => {
  loadArticles()
})
onServerPrefetch(async () => {
  await loadArticles()
})
</script>
