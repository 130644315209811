import { CognitoBase } from '~cognito/models/Cognito/Base'

class LakesideEventCategory extends CognitoBase {
  name: string

  baseurl() {
    return '/api/v1/lakeside/eventCategory'
  }

  constructor(source?: Partial<LakesideEventCategory>) {
    super()
    this.name = ''
    Object.assign(this, source)
  }
}

export { LakesideEventCategory }
