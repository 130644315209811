import { CognitoBase } from '~cognito/models/Cognito/Base'
import { $axios } from '~cognito/plugins/axios'

class LakesideEvent extends CognitoBase {
  name: string
  start_time: string
  end_time: string
  can_enter_online: boolean
  racers_event_id: number
  description: string

  baseurl() {
    return '/api/v1/lakeside/event'
  }

  constructor(source?: Partial<LakesideEvent>) {
    super()
    this.name = ''
    this.start_time = ''
    this.end_time = ''
    this.can_enter_online = false
    this.racers_event_id = 0
    this.description = ''
    Object.assign(this, source)
  }
}

class LakesideCalendarEvent extends CognitoBase {
  title: string
  start: string
  end: string
  backgroundColor: string
  textColor: string

  baseurl() {
    return '/api/v1/lakeside/event'
  }

  constructor(source?: Partial<LakesideCalendarEvent>) {
    super()
    this.title = ''
    this.start = ''
    this.end = ''
    this.backgroundColor = ''
    this.textColor = ''
    Object.assign(this, source)
  }

  async findEvents(start: string, end: string, search: string): Promise<LakesideCalendarEvent[]> {
    const res = await $axios.get(`${this.baseurl()}/calendar`, {
      params: {
        start,
        end,
        search,
      },
    })
    return res.data
  }
}

export { LakesideCalendarEvent, LakesideEvent }
